.hoverbtn{
    cursor: zoom-in;
}
.primaryBtnAlzer {
    background: #ad4252!important; 
    padding: 8px 15px;
    font-size: var(--bodyText);
    color: var(--white);
    text-align: center;
    position: relative;
    letter-spacing: 0.5px;
    transition: 0.5s ease-in-out;
    border: 1px gray solid;
    text-decoration: none;
    font-weight: 500;
    border-radius: 4px;
}
.primaryBtnAlzer:hover {
    color: #ad4252!important;
    background: white!important;
    border:1px gray solid;
  }