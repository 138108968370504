.not-found-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.not-found-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.not-found-content {
  text-align: center;
  z-index: 1;
  padding: 2rem;
}

.not-found-title {
  font-size: 12rem;
  font-weight: 900;
  color: #000;
  line-height: 1;
  margin-bottom: 1rem;
  position: relative;
}

.not-found-o {
  color: #756154;
  position: relative;
  display: inline-block;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.not-found-subtitle {
  font-size: 2.5rem;
  font-weight: 300;
  color: #343a40;
  margin-bottom: 1.5rem;
}

.not-found-message {
  font-size: 1.1rem;
  color: #6c757d;
  margin-bottom: 2rem;
}

.not-found-button {
  padding: 0.75rem 2rem;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.3s ease;
  border-width: 2px;
}

.not-found-button:hover {
  background-color: #756154;
  color: white;
}

.not-found-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  z-index: 0;
}

.not-found-background::before {
  content: "404";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30vw;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.1);
  z-index: -1;
}

@media (max-width: 768px) {
  .not-found-title {
    font-size: 8rem;
  }

  .not-found-subtitle {
    font-size: 2rem;
  }

  .not-found-background::before {
    font-size: 50vw;
  }
}

.btn-outline-primary {
  --bs-btn-color: #756154;
  --bs-btn-border-color: #756154;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #756154;
  --bs-btn-hover-border-color: #756154;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #756154;
  --bs-btn-active-border-color: #756154;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #756154;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #756154;
  --bs-gradient: none;
}