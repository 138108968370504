@import "~react-image-gallery/styles/css/image-gallery.css";
.sticky-column {
  position: sticky;
  top: 130px;
  overflow-y: auto;
}

.room_section {
  margin-top: 20px;
}

@media (max-width: 991px) {
  .sticky-column {
    position: static;
    height: auto;
  }
}

.room-info {
  position: unset!important;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 20px;
}

@media (min-width: 1200px) {
  .col-xl-9 {
    flex: 0 0 auto;
    width: 60%;
  }
}