.room-listalzer {
  border-radius: 0;
}

.room-listalzer .list-group-item {
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.room-listalzer .list-group-item:hover {
  background-color: #f8f9fa;
}

.room-listalzer .list-group-item.active {
  background-color: #af9400;
  color: #fff;
}

.room-listalzer .room-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}