.room-list {
  border-radius: 0;
}

.room-list .list-group-item {
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.room-list .list-group-item:hover {
  background-color: #f8f9fa;
}

.room-list .list-group-item.active {
  background-color: #c69c6d;
  color: #fff;
}

.room-list .room-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}