@import "~react-image-gallery/styles/css/image-gallery.css";

/* RoomDetail.css */
.room-detail-page {
    background-color: #f8f9fa;
  }
  .room-features{
    display: unset!important;
  }
  .room-detail-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
  }
  
  .room-gallery {
    margin-bottom: 2rem;
  }
  
  .main-image-container {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
  }
  
  .main-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  .main-image:hover {
    transform: scale(1.05);
  }
  
  .slider-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.7);
    color: #333;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .slider-button:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .slider-button.prev {
    left: 20px;
  }
  
  .slider-button.next {
    right: 20px;
  }
  
  .thumbnail-container {
    display: flex;
    justify-content: start;
    margin-top: 1rem;
    overflow-x: auto;
    padding-bottom: 10px;
  }
  
  .thumbnail {
    width: 100px;
    height: 75px;
    object-fit: cover;
    margin-right: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .thumbnail:hover, .thumbnail.active {
    opacity: 0.7;
  }
  
  .room-title {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  
  .custom-tabs-alzer .nav-link {
    color: #555;
    font-weight: 600;
    border: none;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
    padding: 0.75rem 1.5rem;
  }
  
  .custom-tabs-alzer .nav-link:hover,
  .custom-tabs-alzer .nav-link.active {
      color: #af9400;  
      background-color: transparent;
      border-bottom: 2px solid #af9400; 
  }
  
  
  .tab-content {
    padding-top: 2rem;
  }
  
  .room-description p,
  .room-features li,
  .room-amenities li,
  .room-location li {
    font-size: 1rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 1rem;
  }
  
  .feature-list,
  .amenity-list,
  .location-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .feature-item-alzer,
  .amenity-list li,
  .location-list li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .feature-item-alzer svg,
  .amenity-list li svg,
  .location-list li svg {
    margin-right: 1rem;
    color: #af9400;
    font-size: 1.2rem;
  }
  
  .booking-card {
    background-color: #fff;
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .price-section {
    margin-bottom: 1.5rem;
  }
  
  .price {
    font-size: 2.5rem;
    font-weight: bold;
    color: #28a745;
  }
  
  .per-night {
    font-size: 1rem;
    color: #6c757d;
  }
  
  .reservation-form .form-label {
    font-weight: 600;
    color: #555;
    margin-right: 15px;
  }
  
  .reservation-form .form-control {
    border-radius: 8px;
    border: 1px solid #ced4da;
    padding: 0.75rem;
  }
  
  .reservation-form .btn-primary {
    background-color: #af9400;
    border-color: #af9400;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .reservation-form .btn-primary:hover {
    background-color: #62514A;  
    border-color: #62514A;
    box-shadow: unset;
  }
  
  .need-help {
    margin-top: 2rem;
    border-top: 1px solid #dee2e6;
    padding-top: 1.5rem;
  }
  
  .need-help h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #333;
    font-weight: 600;
  }
  
  .need-help p {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    color: #555;
  }
  
  .need-help svg {
    margin-right: 0.75rem;
    color: #af9400;
  }
  
  @media (max-width: 991px) {
    .booking-card {
      margin-top: 2rem;
    }
  }
  
  @media (max-width: 767px) {
    .room-title {
      font-size: 2rem;
    }
  
    .main-image {
      height: 350px;
    }
  
    .thumbnail {
      width: 80px;
      height: 60px;
    }
  
    .price {
      font-size: 2rem;
    }
  }
/* Alzer Hotel için kırmızı tema */
.alzer-gallery .image-gallery-thumbnail {
  border-radius: 4px;
  transition: border 0.3s ease-in-out;
}

.alzer-gallery .image-gallery-thumbnail.active,
.alzer-gallery .image-gallery-thumbnail:hover,
.alzer-gallery .image-gallery-thumbnail:focus {
  border: 4px solid #af9400; 
}

.alzer-gallery .image-gallery-bullet {
  background: #ccc;
  transition: background 0.3s ease-in-out;
}

.alzer-gallery .image-gallery-bullet.active {
  background: #af9400;  
}

.alzer-gallery .image-gallery-left-nav,
.alzer-gallery .image-gallery-right-nav {
  padding: 50px 10px;
  transition: all 0.3s ease-in-out;
}

.alzer-gallery .image-gallery-left-nav:hover,
.alzer-gallery .image-gallery-right-nav:hover {
  color: #af9400;
}

.alzer-gallery .image-gallery-play-button,
.alzer-gallery .image-gallery-fullscreen-button {
  transition: all 0.3s ease-in-out;
}

.alzer-gallery .image-gallery-play-button:hover,
.alzer-gallery .image-gallery-play-button:focus {
  color: #af9400 !important;
}

.alzer-gallery .image-gallery-fullscreen-button:hover,
.alzer-gallery .image-gallery-fullscreen-button:focus {
  color: #af9400 !important;
}

.alzer-gallery .image-gallery-icon {
  transition: color 0.3s ease-in-out;
}

.alzer-gallery .image-gallery-icon:hover {
  color: #af9400;
}

.alzer-gallery .image-gallery-thumbnails-wrapper {
  margin-top: 20px;
}

.alzer-gallery .image-gallery-thumbnails {
  padding: 5px 0;
}

.alzer-gallery .image-gallery-thumbnail-image {
  border-radius: 4px;
  height: 80px;
  object-fit: cover;
}

.alzer-gallery .image-gallery-slide {
  border-radius: 8px;
  overflow: hidden;
}

.alzer-gallery .image-gallery-swipe {
  border-radius: 8px;
}

.alzer-gallery .image-gallery-content {
  border-radius: 8px;
}

.alzer-gallery .image-gallery-content.fullscreen {
  background: #000;
}

.alzer-gallery .image-gallery-content.fullscreen .image-gallery-slide {
  border-radius: 0;
}

.alzer-gallery .image-gallery-index {
  background: rgba(0, 0, 0, 0.7);
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
}

/* Responsive ayarlamalar */
@media (max-width: 768px) {
  .alzer-gallery .image-gallery-thumbnail-image {
      height: 60px;
  }
  
  .alzer-gallery .image-gallery-left-nav,
  .alzer-gallery .image-gallery-right-nav {
      padding: 30px 5px;
  }
}
.amenity-card {
  transition: transform 0.3s ease-in-out;
  border: none;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.amenity-card:hover {
  transform: translateY(5px);
}

.amenity-icon-alzer {
  font-size: 1.5rem;
  color: #af9400;
  margin-right: 1rem;
}

.amenity-name {
  font-size: 0.9rem;
  color: #333;
}

.google-map {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.feature-list, .location-list {
  padding-left: 0;
  list-style-type: none;
}

.feature-item-alzer, .location-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.feature-item-alzer svg, .location-list li svg {
  margin-right: 1rem;
  color: #af9400;
  font-size: 1.2rem;
}
.section-title-alzer {
  text-align: center;
  margin-bottom: 2rem;
  position: relative;
}
.section-title-alzer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: #af9400;
}
.section-icon-alzer {
  font-size: 1rem;
  color: #af9400;
  margin-bottom: 0.5rem;
}

.section-title-alzer h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.section-underline-alzer {
  width: 60px;
  height: 3px;
  background-color: #af9400;
  margin: 0 auto;
}

.room-amenities {
  padding-top: 2rem;
}