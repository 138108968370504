.home {
    position: relative;
  }
  .luxury-slider .slide-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    overflow: hidden;
  }
  
  .luxury-slider .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
  }
  
  .slide-content h1, .slide-content h2, .slide-content p {
    margin: 0;
  }
  
  .reservation-filter-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(50%);
    z-index: 10;
  }
  
  @media (max-width: 768px) {
    .reservation-filter-container {
      position: static;
      transform: none;
      padding: 20px;
      background-color: #f0f0f0;
    }
  }
  .tours_section {
    padding: 80px 0;
    background-color: #f8f9fa;
  }
  
  
  .call_us {
    background: url("../../../assets/images/Alzer/exterior.jpg") no-repeat;
    padding: 88px 0px;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    position: relative;
    z-index: 1;
  }
  
  .call_us .title {
    color: var(--primaryClr);
    font-style: italic;
  }
  
  .call_us .heading {
    font-size: 50px;
    color: var(--white);
    line-height: 58px;
    text-align: left;
    font-weight: bold;
    padding: 5px 0 20px 0;
  }
  
  .call_us p {
    font-size: var(--subTitle);
    color: var(--white);
    line-height: 30px;
  }
  
  .call_us .secondary_btn {
    background: none;
    font-size: 18px;
    color: var(--white);
    display: inline-block;
    border: 2px solid var(--white);
    padding: 12px 8px;
    text-decoration: none;
  }
  
  .bounce {
    animation: bounce 2s infinite;
  }
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: #00000054;
    z-index: -1;
  }
  
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  @media only screen and (max-width: 1199px) {
    .call_us {
      overflow: hidden;
    }
  
    .call_us .heading {
      font-size: 28px;
      line-height: 38px;
      padding: 5px 0 10px 0;
    }
  }
  
  @media only screen and (max-width: 420px) {
    .call_us {
      overflow: hidden;
    }
    .call_us .heading {
      font-size: 22px;
    }
  }
  
  .gallery {
      background-color: var(--secondaryClr);
      overflow: hidden;
      padding: 50px 0px;
  }
  
  .gallery .styles_thumbpanel__1sa4E {
      z-index: 9999;
  }
  
  .gallery .styles_lightroom__1X2qE{
      position: fixed;
      overflow: hidden;
      z-index: 9999;
  }
  
  
  
  
  
  .gallery {
    background-color: #fffaf9;
    overflow: hidden;
    padding: 50px 0;
  }
  
  .gallery .styles_thumbpanel__1sa4E {
    z-index: 9999;
  }
  
  .gallery .styles_lightroom__1X2qE {
    position: fixed;
    overflow: hidden;
    z-index: 9999;
  }
  .tours_section {
    padding: 80px 0;
    background-color: #f8f9fa;
  }
  
  .tours_section .main_heading h2 {
    font-size: 32px;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .tours_section .slick-slider {
    margin: 0 -15px;
  }
  
  .tours_section .slider-item {
    padding: 0 15px;
  }
  
  .tours_section .slick-prev,
  .tours_section .slick-next {
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  
  .tours_section .slick-prev:before,
  .tours_section .slick-next:before {
    color: #333;
    font-size: 20px;
  }
  
  .tours_section .slick-prev {
    left: -20px;
  }
  
  .tours_section .slick-next {
    right: -20px;
  }
  
  @media (max-width: 768px) {
    .tours_section .slick-prev,
    .tours_section .slick-next {
      display: none !important;
    }
  }