html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1 0 auto;
}

.footerAlzer {
  flex-shrink: 0;
  background-color: #dfdfde;
  color: black;
  padding: 60px 0 0;
  font-size: 14px;
}

/* Diğer footerAlzer stilleri aynı kalacak */

.copyright {
  background-color: #1a252f;
  color: #bdc3c7;
  padding: 15px 0;
  margin-top: 40px;
  text-align: center;
}

@media (max-width: 768px) {
  .footerAlzer-content > div {
    margin-bottom: 30px;
  }

  .footerAlzer-bottom > div {
    margin-bottom: 20px;
  }
}
.footerAlzer {
    background-color: #dfdfde;
    color: black;
    padding: 60px 0 0;
    font-size: 14px;
  }

  .footerAlzer h5 {
    color: #ad4252 !important;
    margin-bottom: 20px;
    font-weight: 600;
  }

  .footerAlzer ul {
    list-style: none;
    padding: 0;
  }

  .footerAlzer ul li {
    margin-bottom: 10px;
  }

  .footerAlzer a {
    color: black;
    text-decoration: none;
    transition: color 0.3s ease;
  }

  .footerAlzer a:hover {
    color: #ad4252 !important;
  }

  .social-icons {
    margin-top: 20px;
  }

  .social-icons a {
    display: inline-block;
    margin-right: 15px;
    font-size: 18px;
  }

  .footerAlzer-bottom {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #34495e;
  }

  .partner-logos img {
    height: 40px;
    margin-right: 20px;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
  }

  .partner-logos img:hover {
    filter: grayscale(0%);
  }

  .newsletter-form {
    display: flex;
    margin-top: 15px;
  }

  .newsletter-form input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 4px 0 0 4px;
  }

  .newsletter-form button {
    background-color: #ad4252;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .newsletter-form button:hover {
    background-color: #fff;
    box-shadow: 0 0 20px #ad4252, 0 0 40px #ad4252, 0 0 60px #ad4252;
    color: #000;
  }

  .copyright {
    background-color: #1a252f;
    color: #bdc3c7;
    padding: 15px 0;
    margin-top: 40px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .footerAlzer-content > div {
      margin-bottom: 30px;
    }

    .footerAlzer-bottom > div {
      margin-bottom: 20px;
    }

    .partner-logos, .newsletter {
      text-align: center;
    }

    .newsletter-form {
      flex-direction: column;
    }

    .newsletter-form input, .newsletter-form button {
      width: 100%;
      border-radius: 4px;
      margin-top: 10px;
    }
  }