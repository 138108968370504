.selection-container {
  min-height: 100vh;
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selection-title {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: 600;
}

.hotels-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 400px));
  gap: 2rem;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
}

.hotel-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.hotel-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0,0,0,0.2);
}

.hotel-image {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.hotel-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.hotel-card:hover .hotel-image img {
  transform: scale(1.1);
}

.hotel-info {
  padding: 1.5rem;
  text-align: center;
}

.hotel-info h2 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
}

.hotel-info p {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.visit-btn {
  background: #2c3e50;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 25px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  cursor: pointer;
}

.visit-btn:hover {
  background: #34495e;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .selection-title {
    font-size: 2rem;
  }
  
  .hotels-grid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
}

.split-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.split {
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: all 0.8s ease-in-out;
  cursor: pointer;
}

.split.left {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
              url('../assets/images/selectionPage/a6dd0056_z.jpg') center/cover;
}

.split.right {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
              url('../assets/images/selectionPage/exterior.jpg') center/cover;
}

.split:hover {
  width: 60%;
}

.split:hover + .split {
  width: 40%;
}

.hotel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  padding: 2rem;
  color: white;
  opacity: 0.9;
  transition: all 0.3s ease;
}

.split:hover .hotel-content {
  opacity: 1;
}

.logo-container {
  margin-bottom: 2rem;
}

.hotel-logo {
  max-width: 200px;
  height: auto;
  filter: brightness(0) invert(1);
  transition: all 0.3s ease;
}

.split:hover .hotel-logo {
  transform: scale(1.1);
}

.hotel-details h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: 300;
 
  letter-spacing: 3px;
}

.hotel-details p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  font-weight: 300;
}

.explore-btn {
  display: inline-block;
  padding: 1rem 3rem;
  border: 2px solid white;
  color: white;
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.explore-btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: white;
  transition: all 0.3s ease;
  z-index: -1;
}

.split:hover .explore-btn:before {
  left: 0;
}

.split:hover .explore-btn {
  color: black;
}

@media (max-width: 768px) {
  .split-container {
    flex-direction: column;
  }

  .split {
    width: 100%;
    height: 50vh;
  }

  .split:hover {
    width: 100%;
    height: 60vh;
  }

  .split:hover + .split {
    width: 100%;
    height: 40vh;
  }

  .hotel-details h2 {
    font-size: 2rem;
  }

  .hotel-details p {
    font-size: 1rem;
  }
} 