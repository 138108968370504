@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Roboto:wght@300;400;500;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  color: #333;
}

h1, h2, h3, h4, h5, h6 {
 
}
.Alzer-color{
  color:#af9400;
}
.section-title-Alzer {
  font-size: 2.5rem;
  font-weight: 700;
  color: #af9400;
  position: relative;
  padding-bottom: 1rem;
}

.section-title-Alzer::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background-color: #af9400;
}

.about-image-content {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  line-height: 1.8;
}

.video-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.play-button-Alzer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: #af9400;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.play-button-Alzer:hover {
  background-color: #af9400;
  transform: translate(-50%, -50%) scale(1.1);
}

.play-icon {
  color: white;
}

.feature-card {
  transition: all 0.3s ease;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  height: 100%;
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.info-card {
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.info-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.feature-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.feature-list li {
  padding: 0.5rem 0;
  font-size: 1.1rem;
  color: #555;
}

@media (max-width: 768px) {
  .about-image-content {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  .section-title-Alzer {
    font-size: 2rem;
  }

  .play-button-Alzer {
    width: 60px;
    height: 60px;
  }

  .feature-card {
    margin-bottom: 1.5rem;
  }
}

.modal-content {
  border-radius: 15px;
  overflow: hidden;
}

.modal-header {
  background-color: #af9400;
  color: white;
  border: none;
}

.modal-header .close {
  color: white;
}